import _ from 'underscore';

import api from '@lib/api';

import { CUSTOMER_TYPE_BUSINESS, CUSTOMER_TYPE_RESIDENTIAL } from './onboarding-account-model';
import { PACKAGED_NUMBER_REFUND_ID } from './price-model';

class PriceConnector {
    priceCollection = null
    accountModel = null

    constructor(priceCollection, accountModel) {
        this.priceCollection = priceCollection;
        this.accountModel = accountModel;
    }

    connect() {
        if (this.accountModel.get('hasTrial')) {
            this.priceCollection.setTrialPrices();
        }

        this.setupPlan();
        this.setupBuyNumbers();
        this.setupHardware();
        this.setupBroadband();
        this.validateBasket();
        this.accountModel.on('change:plan validate:hardware', () => this.validateHardware());
        this.accountModel.on('change:buyNumbers change:plan', () => {
            const needsNumberRefund = this.accountModel.hasPackagedService &&
                this.accountModel.hasGeoNumber;
            const hasNumberRefund = this.accountModel.hasPackagedNumberRefund;

            if (needsNumberRefund && !hasNumberRefund) {
                this.accountModel.addProduct(this.priceCollection.getProduct(PACKAGED_NUMBER_REFUND_ID));
            } else if (!needsNumberRefund && hasNumberRefund) {
                const numberRefund = this.accountModel.getBasketItems()
                    .find(item => item.product.sku === PACKAGED_NUMBER_REFUND_ID);
                this.accountModel.removeProduct(numberRefund.product);
            }
        });
        this.accountModel.on('change:hardware', () => {
            const { hardware } = this.accountModel.attributes;

            if (hardware.length === 0) {
                this.accountModel.get('deliveryAddress').set('useAccountAddress', true);
            }
        });
        this.accountModel.on('validate:basket', () => this.validateBasket());
    }

    setupPlan() {
        const { _plan, _planSeats } = this.accountModel.attributes;
        if (_plan) {
            const planProduct = this.priceCollection.getProduct(_plan);
            this.accountModel.addProduct(planProduct, _planSeats);
            this.accountModel.set('customerType', planProduct.isResidential
                ? CUSTOMER_TYPE_RESIDENTIAL
                : CUSTOMER_TYPE_BUSINESS);
        }
    }

    setupBuyNumbers() {
        const { _buyNumbers } = this.accountModel.attributes;
        if (_buyNumbers) {
            _buyNumbers.forEach(item => {
                const [sku, number] = item.split(',');
                this.accountModel.addProduct(this.priceCollection.getProduct(sku)?.create(number));
            });
        }
    }

    setupHardware() {
        const { _hardware } = this.accountModel.attributes;
        if (_hardware) {
            Object.keys(_hardware).forEach(sku => {
                this.accountModel.addProduct(this.priceCollection.getProduct(sku), _hardware[sku]);
            });
        }

        const { hardware } = this.accountModel.attributes;
        hardware.sort((a, b) => a.product.totalLines < b.product.totalLines);
        this.accountModel.set('hardware', hardware);

        hardware.forEach(item => {
            item.configuration?.fetch({
                id: item.cid,
                accountModel: this.accountModel,
                product: item.product,
            });
            item.configuration?.on('change', () => item.trigger('change'));
        });
    }

    setupBroadband() {
        const { _broadband } = this.accountModel.attributes;

        if (_broadband) {
            Object.keys(_broadband).forEach(key => {
                _broadband[key].skus?.forEach(item => {
                    const [sku, number=null] = item.split(',');
                    this.accountModel.addProduct(this.priceCollection.getProduct(sku)?.create(number));
                });
            });

            this.accountModel.get('broadband').forEach(item => {
                item.configuration?.fetch({
                    ..._broadband[item.product.number],
                    number: item.product.number,
                });
            });
        }
    }

    validateHardware() {
        const { hardware } = this.accountModel.attributes;
        hardware.forEach(item => {
            if (!item.configuration?.isValid()) {
                item.configuration?.reset();
            }
        });
    }

    validateBasket() {
        const basketItems = this.accountModel.getBasketItems();
        if (basketItems.length === 0) return;

        const basket = this.accountModel.basketParameters;
        const params = {
            cart: JSON.stringify(basket),
            broadband: JSON.stringify(this.accountModel.broadbandParameters),
            referred_by: this.accountModel.get('hasTrial') ? 1 : 0,
        };

        api.v0.post('web_shop', params).then(([, data, errors]) => {
            this.removeBasketItems(basket, data);
            this.addBasketItems(basket, data);
            this.removeDuplicateNumbers();
            this.accountModel.getBasketItems().forEach(item => {
                const quantity = data[item.product.uid].quantity;
                this.accountModel.updateProduct(item.product, quantity);
            });
            this.accountModel.trigger('validated:basket', errors);
        });
    }

    removeBasketItems(originalBasket, validatedBasket) {
        const items = _.difference(_.keys(originalBasket), _.keys(validatedBasket));
        const basketItems = this.accountModel.getBasketItems().filter(item => {
            return _.contains(items, item.product.uid);
        });
        basketItems.forEach(item => this.accountModel.removeProduct(item.product));
    }

    addBasketItems(originalBasket, validatedBasket) {
        const items = _.difference(_.keys(validatedBasket), _.keys(originalBasket));
        const products = _.compact(items.map(item => {
            const [sku, number] = item.split(',');
            const product = this.priceCollection.getProduct(sku);
            return number ? product?.create(number) : product;
        }));
        products.forEach(product => {
            const quantity = validatedBasket[product.uid].quantity;
            this.accountModel.addProduct(product, quantity);
        });
    }

    removeDuplicateNumbers() {
        const items = _.groupBy(this.accountModel.get('buyNumbers'), item => item.product.number);
        Object.keys(items).forEach(key => {
            const standardNumber = items[key].find(item => item.product.oneOffPrice === 0);
            if (standardNumber && items[key].length > 1) {
                this.accountModel.removeProduct(standardNumber.product);
            }
        });
    }
}


export {
    PriceConnector,
};
