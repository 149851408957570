import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import { Cart, ChevronRight } from 'react-bootstrap-icons';

import { ButtonBar, ErrorList } from '@components';
import fmt from '@lib/formatters';
import { useBasket, useDidMount, useSend } from '@lib/hooks';

import Basket from './basket';
import PaymentForm from './payment-form';


const title = 'Confirm Order';


const BasketSummary = ({ basket }) => (
    <Row className="align-items-center g-2 g-sm-0">
        <Col xs={12} sm={8} className="fw-bold text-body">
            <Cart />
            <span className="ms-2 me-2 align-middle">Order summary</span>
        </Col>
        <Col className="text-sm-end">
            <span className="fs-5">
                &pound;{fmt.price(basket.totalIncVat())}
                &nbsp;
            </span>
            <small>
                (&pound;{fmt.price(basket.totalExVat())} ex. VAT)
            </small>
        </Col>
    </Row>
);


function PaymentPage() {
    const [continueButtonEnabled, setContinueButtonEnabled] = useState(false);
    const [hasValidated, setHasValidated] = useState(false);
    const [showCheckout, setShowCheckout] = useState(false);

    const basket = useBasket();
    const send = useSend();

    useDidMount(() => basket.validate());

    useEffect(() => {
        if (basket.count === 0) {
            send('NAVIGATE-CONTACT');
        }
    }, [basket.count]);

    useEffect(() => {
        if (basket.isValidating) {
            setHasValidated(true);
        }
        setContinueButtonEnabled(hasValidated && !basket.isValidating);
    }, [basket.isValidating, hasValidated]);

    const onContinueClicked = () => {
        setShowCheckout(true);
    };

    if (showCheckout) {
        return (
            <div>
                <h2>Payment</h2>

                <PaymentForm />
            </div>
        );
    } else {
        return (
            <div>
                <h2>Confirm Order</h2>

                { basket.validationErrors.length > 0 &&
                    <ErrorList errors={basket.validationErrors} />
                }

                <Card bg="light">
                    <Card.Body>
                        { (!hasValidated || basket.isValidating) && (
                            <Spinner
                                variant="purple"
                                animation="border"
                                className="align-middle"
                            />
                        ) || (
                            <React.Fragment>
                                <BasketSummary basket={ basket} />
                                <Basket />
                            </React.Fragment>
                        )}
                    </Card.Body>
                </Card>

                <br />

                <ButtonBar>
                    <Button
                        variant="success"
                        disabled={ !continueButtonEnabled }
                        onClick={ onContinueClicked }
                    >
                        { basket.isFreePayment() ?
                            'Create Account' : 'Continue to Payment'} <ChevronRight />
                    </Button>
                </ButtonBar>
            </div>
        );
    }
}


export default PaymentPage;

export {
    title,
};
