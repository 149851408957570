const ID_GROUPS = 'group';
const ID_IVR_MULTI = 'ivrMultiLevel';
const ID_IVR_SINGLE = 'ivrSingleLevel';
const ID_MIXED_MINUTES = 'mixedMinutes';
const SERVICES_WITH_MULTIPLE = [ID_GROUPS, ID_IVR_MULTI, ID_IVR_SINGLE];

const SERVICES_BUSINESS = [
    {
        id: ID_GROUPS,
        name: 'Call Groups',
        url: 'services/call-groups',
    },
    {
        id: 'callbar',
        name: 'Call Barring',
        url: 'services/call-barring',
    },
    {
        id: 'queue',
        name: 'Call Queue',
        url: 'services/call-queuing',
    },
    {
        id: 'cmbpro',
        name: 'Call Me Back Pro',
        url: 'services/standard/call-me-back',
    },
    {
        id: ID_IVR_SINGLE,
        name: 'IVRs (Single Menu)',
        url: 'services/pbx/ivr',
    },
    {
        id: ID_IVR_MULTI,
        name: 'IVRs (Multi Menu)',
        url: 'services/pbx/ivr',
    },
    {
        id: 'cloudcallrecording',
        name: 'Cloud Call Recording',
        url: 'services/call-recording',
    },
    {
        id: 'custommoh',
        name: 'Custom Music on Hold',
        url: 'services/custom-music-on-hold',
    },
];

const SERVICES_RESIDENTIAL = [
    {
        name: 'Keep your number, or get a new one',
    },
    {
        name: 'Voicemail',
    },
    {
        name: 'Call Forwarding',
    },
    {
        name: 'Call Blocking',
    },
];

export {
    ID_GROUPS,
    ID_IVR_MULTI,
    ID_IVR_SINGLE,
    ID_MIXED_MINUTES,
    SERVICES_BUSINESS,
    SERVICES_RESIDENTIAL,
    SERVICES_WITH_MULTIPLE,
};
