import React from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Button, Spinner } from 'react-bootstrap';

import { REDIRECT_AFTER_SECS } from '@lib/constants';
import { useDidMount } from '@lib/hooks';
import SimpleWin from '@components/simple-win';


const title = 'Setup Complete';

const STATUS_ABORT = 'abort';
const STATUS_AUTH = 'notauthed';
const STATUS_CARD = 'card';
const STATUS_DECLINED = 'declined';
const STATUS_ERROR = 'error';
const STATUS_OK = 'ok';
const STATUS_PENDING = 'pending';
const STATUS_REJECTED = 'rejected';
const STATUS_REVIEW = 'review';
const STATUS_SOFT_DECLINE = 'softDecline';


function getPageData(status) {
    switch (status) {
        case STATUS_REVIEW:
        case STATUS_OK:
            return {
                message: "Thank you, your purchase is complete",
                type: 'success',
                redirect: true,
            };

        case STATUS_ABORT:
            return {
                message: "Your transaction has been cancelled",
                type: 'warning',
            };

        case STATUS_DECLINED:
        case STATUS_AUTH:
        case STATUS_REJECTED:
            return {
                message: "Sorry, your transaction was declined",
                type: 'danger',
            };

        case STATUS_SOFT_DECLINE:
            return {
                message: "Sorry, your transaction was declined",
                description: "Your transaction was declined due to changes " +
                    "to your bank's security requirements. Please re-enter " +
                    "your card details.",
                type: 'danger',
            };

        case STATUS_CARD:
        case STATUS_ERROR:
        case STATUS_PENDING:
        default:
            return {
                message: "Sorry, there was a problem with your transaction.",
                type: 'danger',
            };
    }
}


function CallbackHandlerPage() {
    const { account, status } = useParams();
    const { description, message, redirect, type } = getPageData(status);
    let inIFrame = false;

    try {
        inIFrame = window.self !== window.top;
    } catch (e) {}

    useDidMount(() => {
        if (inIFrame) window.top.location.href = window.location.href;
        if (!redirect) return;

        setTimeout(() => location.href = `/${ account }`, REDIRECT_AFTER_SECS * 1000);
    });

    if (inIFrame) return "";

    return (
        <SimpleWin>
            <h2>Checkout</h2>

            { message && <Alert variant={type || 'warning' }>{ message }</Alert> }

            { description && <p>{ description }</p> }

            { redirect && (
                <Spinner animation="border" />
            ) || (
                <Button href={ `/${ account }` }>Try Again</Button>
            )}
        </SimpleWin>
    );
}


export default CallbackHandlerPage;

export {
    title,
};
