import dayjs from 'dayjs';

import AddressModel from '@content/contact-page/address-model';
import { DEFAULT_COUNTRY } from '@lib/geo-country';
import { BaseModel } from '@lib/models';
import utils from '@lib/utils';


class BroadbandConfigurationModel extends BaseModel {
    static defaults = {
        firstname: '',
        surname: '',
        email: '',
        companyName: '',
        mobileNumber: '',
        currentIsp: '',
        _address: null,
        address: new AddressModel(),
        package: 0,
        number: '',
        line: '',
        router: '',
        requestInstallationDate: false,
        requestedInstallationDate: null,
        downloadSpeed: 0,
        uploadSpeed: 0,
        staticIp: false,
    }

    validation = {
        requestedInstallationDate: {
            fn: val => {
                if (val !== null && (val.isBefore(this.minInstallationDate, 'day') ||
                    val.isAfter(this.maxInstallationDate, 'day'))) {
                    return 'Invalid installation date';
                }
            },
        }
    }

    // These are the only attributes configurable by the user at this stage
    get parameters() {
        const requestedDate = this.get('requestedInstallationDate');
        return {
            requested_installation_date: requestedDate?.isValid?.() ? requestedDate.format('YYYY-MM-DD') : null,
            router: this.get('router') || null,
            static_ip: this.get('staticIp') ? 'y' : 'n',
        };
    }

    // These are all the attributes upd/web_shop requires to validate a broadband order
    get allParameters() {
        return {
            address: this.get('_address'),
            name: this.get('firstname'),
            surname: this.get('surname'),
            email: this.get('email'),
            company_name: this.get('companyName'),
            mobile_number: this.get('mobileNumber'),
            current_isp: this.get('currentIsp'),
            package: this.get('package'),
            number: this.get('number'),
            line: this.get('line'),
            download_speed: this.get('downloadSpeed'),
            upload_speed: this.get('uploadSpeed'),
            ...this.parameters,
        };
    }

    get minInstallationDate() {
        return dayjs().add(7, 'day');
    }

    get maxInstallationDate() {
        return dayjs().add(60, 'day');
    }

    parse(data) {
        return {
            firstname: String(data.name),
            surname: String(data.surname),
            email: String(data.email),
            companyName: String(data.company_name),
            mobileNumber: String(data.mobile_number),
            currentIsp: String(data.current_isp),
            _address: data.address,
            address: this.parseInstallationAddress(data.address),
            requestedInstallationDate: this.parseInstallationDate(data.requested_installation_date),
            package: parseInt(data.package),
            number: String(data.number),
            line: String(data.line),
            router: data.router || '',
            downloadSpeed: parseInt(data.download_speed),
            uploadSpeed: parseInt(data.upload_speed),
            staticIp: data.static_ip === 'y',
        };
    }

    parseInstallationDate(date) {
        const formats = ['YYYY-MM-DD', 'DD/MM/YYYY'];

        for (const format of formats) {
            const _date = utils.convertDate(date, format);

            if (_date.isValid() && !_date.isBefore(this.minInstallationDate, 'day')) {
                return _date;
            }
        }

        return null;
    }

    parseInstallationAddress(addressJson) {
        const houseNumberKeys = ['subpremises', 'name', 'number'];
        const addressModel = new AddressModel();
        let address = null;

        try {
            address = JSON.parse(addressJson);
        } catch (e) {
            // The broadband installation address is shown here for "display purposes" only and
            // can no longer be updated by customers at this stage. In the unlikely event of a
            // malformed address, it will be caught later on during server-side validation.
        }

        if (address !== null) {
            const address1 = houseNumberKeys.map((key) => address[key] || '').join(' ').trim();

            addressModel.set({
                address1: address1,
                address2: String(address.street),
                city: String(address.town),
                postcode: String(address.postcode),
                country: DEFAULT_COUNTRY,
            });
        }

        return addressModel;
    }

    read(model, options) {
        this.set(this.parse(options));
    }
}


export default BroadbandConfigurationModel;
