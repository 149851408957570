
export const BASE_VF_URL = process.env.REACT_APP_WEBSITE_BASE_URL;
export const BASE_CP_URL = process.env.REACT_APP_CP_BASE_URL;
export const BASE_MEDIA_HW_URL = `${process.env.REACT_APP_WEBSITE_BASE_URL}/media/images/hardware`;
export const REDIRECT_AFTER_SECS = 3;
export const VAT_RATE = 1.2;
export const VF_PHONE_NUMBER = '0345 868 5555';

export default {
    BASE_VF_URL,
    BASE_CP_URL,
    BASE_MEDIA_HW_URL,
    REDIRECT_AFTER_SECS,
    VAT_RATE,
    VF_PHONE_NUMBER,
};
