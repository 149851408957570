import React, { useState } from 'react';
import { Button, Stack } from 'react-bootstrap';
import _ from 'underscore';

import constants from '@lib/constants';
import fmt from '@lib/formatters';
import { useAccount, useCheckViewport, usePriceList, useSend } from '@lib/hooks';
import { ButtonBar, NextButton, Notifications, ResidentialNumberPopup } from '@components';

import planFmt from './plan-formatters';
import BusinessPlansDesktop from './business-plans-desktop';
import BusinessPlansMobile from './business-plans-mobile';
import ResidentialPlansDesktop from './residential-plans-desktop';
import ResidentialPlansMobile from './residential-plans-mobile';

import './plan-page.scss';

const title = 'My Plan';


function PlanPage() {
    const send = useSend();
    const { model: accountModel, fixedAttrs: { plan: activePlan } } = useAccount();
    const { plans: { paid: paidPlans }, getProduct } = usePriceList();
    const showMobileView = useCheckViewport({ width: 1280 });
    const [showNumberPopup, setShowNumberPopup] = useState(false);
    const allPaidPlans = _.sortBy(paidPlans, 'renewalPrice');
    const businessPlans = allPaidPlans.filter(item => !item.isResidential);
    const residentialPlans = allPaidPlans.filter(item => item.isResidential);
    const promoPlans = paidPlans.filter(plan => plan.partnerCode);
    const plans = accountModel.isResidential ? residentialPlans : businessPlans;
    const PlansMobile = accountModel.isResidential ? ResidentialPlansMobile : BusinessPlansMobile;
    const PlansDesktop = accountModel.isResidential ? ResidentialPlansDesktop : BusinessPlansDesktop;

    const onSwitchCustomerTypeClicked = () => {
        if (accountModel.isBusiness && !accountModel.isBasketResidentialCompatible) {
            setShowNumberPopup(true);
        } else {
            accountModel.toggleCustomerType();
        }
    };

    const onNumberPopupConfirmed = (selectedNumber) => {
        accountModel.setNumber(selectedNumber);
        accountModel.toggleCustomerType();
        send('SWITCH-RESIDENTIAL');
        setShowNumberPopup(false);
    };

    return (
        <div>
            <h2>Choose Your Plan</h2>

            <Notifications />

            {accountModel.isBusiness &&
                <>
                    <p>Our ready-made plans start from as little as &pound;5 per user
                    per month, or take complete control over your plan with Voipfone Flex.
                    Just pick a plan that suits you today and switch to a different plan
                    when your needs change. And with our Voipfone Flex, 100, 500, 1000
                    and Unlimited plans, you're not tied down to a contract so you can
                    easily change plan every month, directly from your Voipfone Control
                    Panel.</p>

                    <p>All of our plans include our standard features: Voicemail,
                    Business Hours, Call Diverts, Call Pickup, Softphone
                    App <a className="fw-bold text-body" href={`${constants.BASE_VF_URL}/services/pbx/features`}
                    target="_blank" rel="noopener noreferrer">and more</a>.</p>
                </>
            }

            {accountModel.isResidential &&
                <>
                    <p>Our residential plans start from as little as &pound;6 per month.
                    Just pick a plan that suits you today, and switch to a different plan
                    if your needs change.</p>

                    <p>With all Voipfone plans, you're not tied down to a contract so you can
                    easily change plan every month, directly from your Voipfone Control Panel.</p>
                </>
            }

            {plans.length === 0 &&
                <p className="text-center">Loading&hellip;</p>
            }

            {plans.length > 0 && showMobileView &&
                <PlansMobile plans={plans} />
            }

            {plans.length > 0 && !showMobileView &&
                <PlansDesktop plans={plans} />
            }

            <ButtonBar>
                <Stack gap={1}>
                    <div>
                        <Button variant="link" onClick={onSwitchCustomerTypeClicked}>
                            Change to {accountModel.isResidential ? 'business' : 'residential'} account
                        </Button>
                    </div>

                    {!activePlan && accountModel.isBusiness &&
                        <div>
                            <Button variant="link" onClick={() => send('SKIP')}>
                                <small>I'll choose my plan later</small>
                            </Button>
                        </div>
                    }
                </Stack>

                <NextButton disabled={!activePlan} onClick={() => send('NAVIGATE-NEXT')} />
            </ButtonBar>

            <hr />

            {promoPlans.map(plan => {
                const renewalPlan = getProduct(plan.renewalSku);
                const percent = planFmt.getPercentOff(plan, renewalPlan);
                return (
                    <p key={plan.uid} className="small">The {plan.partnerCode.toUpperCase()} special offer
                    is for {plan.description} at {percent === 50 ? 'half-price' : `${percent}%`} (&pound;
                    {fmt.price(plan.price, true)} per month ex VAT) for the first {plan.contractLength} months.
                    The plan will then revert to the standard {plan.description} at &pound;
                    {fmt.price(renewalPlan.price, true)} per month ex VAT.</p>
                );
            })}

            <p className="small">All Voipfone Free Trials (including Voipfone
            Unlimited) include 15 minutes of calls to UK landlines or mobiles, to
            enable you to try out the included services. To make more calls, you
            can either top up your account with calling credit, or upgrade your
            trial to its paid-for equivalent.</p>

            {accountModel.isBusiness &&
                <p className="small">Voipfone Unlimited is designed for businesses
                that use the telephone for their normal business activities. Call
                centres, telemarketers and re-sellers are specifically excluded from
                this service. It is not permitted for customers to use Voipfone
                Unlimited with their own PBX. Full details of this and other
                exclusions can be found in our <a
                href={`${constants.BASE_VF_URL}/policies/terms`} target="_blank"
                rel="noopener noreferrer">Terms and Conditions</a>. None of these
                exclusions will affect a normal business telephone user.</p>
            }

            <p className="small">Voipfone Plans include calls to 01, 02 and 03
            landline numbers and 07 mobile numbers. Calls to Crown Dependency
            landlines (i.e. Jersey, Guernsey and the Isle of Man) are not
            included in Voipfone Plans. Mobile networks included in these
            plans: Vodafone, Virgin, O2, EE, Three, plus their resellers such as
            Tesco and ASDA. They exclude specialist networks such as Manx and
            Jersey.</p>

            {showNumberPopup &&
                <ResidentialNumberPopup
                    onConfirm={onNumberPopupConfirmed}
                    onCancel={() => setShowNumberPopup(false)}
                />
            }
        </div>
    );
}


export default PlanPage;

export {
    title,
};
